.logo-text{
    font-size: 20px;
    color: white;
    margin-top: 10px;
}

.slick-slider{
    margin-left: 10px;
    margin-right: 10px;
}

.slick-slider img{
    border-radius: 20px;
}

.slick-dots{
    bottom: -10px !important;
}

.left-logo img{
    height: 100px;
}

.right-logo img{
    height: 100px;
}

.header-mobile-new{
    overflow: hidden;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: var(--clr);
    height: 50px;
    display: flex;
}

.header-mobile-new img{
    margin-top:2px;
    height: 45px;
}

.header-mobile-new-menu-button {
    padding: 10px;
    width: 20%;
}

.header-mobile-new-menu-button svg {
    fill: #fff;
    height: 24px;
}

.header-mobile-new-businessname{
    padding:2px;
    width: 60%;
    text-align: center;
    padding-top:0px !important;
}

.header-mobile-businessname img{
    height: 45px;
  
}

.header-height{
    /* height:45px; */
    height: 116px;
    margin-bottom: 10px;
}


.header-search-bar-container{
    background-color: var(--clr);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding:10px;
    /* margin-bottom: 10px; */
    overflow: hidden;
    width: 100%;
    position: fixed;
    top: 45px;
    z-index: 100;
}

.header-search-bar {
    display: flex;
    height: 46px;
    border-radius: 32px;
    border: 1px solid var(--btn-border-clr);
    background: white;
    width: 100%;
}

.header-search-bar div {
    height: auto;
    border: none;
    padding-top: 10px;
    width: 80%;
}

.header-search-bar svg{
    height: 24px;
    fill: gray;
    margin-top:10px;
    margin-left:10px;
    margin-right:10px
}

.menu-sub-item{
    margin-left: 20px;
}