.search-mobile-window-header {
    position: fixed;
    height: 50px;
    width: 100%;
    /* padding: 14px; */
    display: flex;
    /* -webkit-box-align: center;
    align-items: center;
    align-self: stretch; */
    /* justify-content: space-between; */
    /* border-bottom: 1px solid #dee3e7; */
    background-color:var(--clr);
    z-index: 600;
}

.search-window-header-close-btn{
    width: 55px;
}

.search-window-header-close-btn svg {
    fill: #fff;
    height: 30px;
    margin-top: 10px;
    margin-left: 10px;
}

.search-bar-container{
    width: 100%;
    margin-right: 10px;
}

.search-bar {
    display: flex;
    height: 35px;
    margin-top:7px;
    border-radius: 32px;
    border: 1px solid var(--btn-border-clr);
    background: #fff;
    width: 100%;
}

.search-bar svg {
    height: 24px;
    fill: grey;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}

.search-bar input{
    width: 80%;
    border:none
}


.search-bar input:focus {
    outline: none;
}