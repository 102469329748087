
.login-page{
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
}

.login-page-left-content{
    padding: 10px;
    margin-top:50px;
}

.login-page-right-content{
    padding: 10px;
    margin-top:50px;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: rgb(204, 204, 204) !important;
    box-shadow: rgb(59 57 112 / 50%) 0px 8px 10px !important;
    border-radius: 11px !important;
    background: rgba(245, 245, 245, 0.8) !important;
    /* width: 33%; */

}

.login-page-background-image{
    min-height: 488px !important;
}