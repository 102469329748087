.css-2b097c-container{
    width: 75%;
}

.store-locator-heading{
    padding: 10px 0px;
    font-weight: bold;
}

.store-locator-list{

}

.store-locator-list-item{
padding: 10px 10px;
border:solid 1px #ddd;
cursor: pointer
}

.store-locator-list-item-header{
    font-size: 14px;
    font-weight: bold;
}

.store-locator-list-item-text{

}

.btn-store-locator-locate-me{
    border:solid 1px #ddd;
}

.store-locator-error{
    color:red;
}

.wallet-recharge-btn{
    background-color: var(--clr);
    border:1px solid var(--clr);
    color: white;
}

.variant-attribute{
    font-size: 14px;
    font-weight: bold;
}

.variant-attribute-values{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.varaint-attribute-value{
    border:solid 1px black;
    padding:10px;
    margin-right: 10px;
    min-width: 100px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
}

.varaint-attribute-value-selected{
    border:solid 1px var(--clr);
    color: var(--clr);
    padding:10px;
    margin-right: 10px;
    min-width: 100px;
    margin-bottom: 10px;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
}

.product-detail-title{
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: bold;
}

.product-detail-mrp{
    font-size: 26px;
    margin-bottom: 10px;
    font-weight: bold;
}

.product-detail-retail-price{
    font-size: 26px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
}

.product-list-btn{
    margin-bottom: 10px;
}

.product-detail-sku-lable{
    font-size: 14px;
    font-weight: bold;
}

.product-detail-sku{
    /* font-size: 14px; */
    margin-bottom: 10px;
    
}

.product-detail-barcode-lable{
    font-size: 14px;
    font-weight: bold;
}

.product-detail-barcode{
    /* font-size: 14px; */
    margin-bottom: 10px;
    
}

.product-detail-description-label{
    font-size: 14px;
    font-weight: bold;
}

.product-detail-description{
   margin-bottom: 10px;
}

.product-detail-long-description{
    margin-bottom: 10px;
 }

 .product-detail-customfield1-lable{
    font-size: 14px;
    font-weight: bold;
 }

 .product-detail-customfield1{
    margin-bottom: 10px;
 }

 .product-detail-customfield2-lable{
    font-size: 14px;
    font-weight: bold;
 }

 .product-detail-customfield2{
    margin-bottom: 10px;
 }

 .product-detail-customfield3-lable{
    font-size: 14px;
    font-weight: bold;
 }

 .product-detail-customfield3{
    margin-bottom: 10px;
 }

 .product-detail-customfield4-lable{
    font-size: 14px;
    font-weight: bold;
 }

 .product-detail-customfield4{
    margin-bottom: 10px;
 }

 .product-detail-customfield5-lable{
    font-size: 14px;
    font-weight: bold;
 }

 .product-detail-customfield5{
    margin-bottom: 10px;
 }

 .product-detail-customfield6-lable{
    font-size: 14px;
    font-weight: bold;
 }

 .product-detail-customfield6{
    margin-bottom: 10px;
 }

.product-add-btn{
    color: var(--clr);
    width: 90px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    border: solid 1px var(--clr);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
}

.product-add-btn > div{
    width: 33%;
}