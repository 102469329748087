.maintenance-container {
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top:100px;
    max-width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  h1 {
    color: #333;
  }
  p {
    color: #666;
  }
  .brand-logo {
    margin-bottom: 20px; 
  }
  .brand-logo img{
    height: 100px;
  }