.level2-mobile-header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    display: flex;
    background-color: var(--clr);
    color: white;
    z-index: 1000;
}

.level2-mobile-header-close-btn{
    width: 20%;
}

.level2-mobile-header-close-btn svg{
    fill: white;
    height: 30px;
    margin-top: 10px;
    margin-left: 10px;
    /* padding-top:10px;
    padding-left: 10px; */
}

.level2-mobile-header-title{
    width: 60%;
    text-align: center;
    margin-top: 10px;
}

.level2-mobile-header-placeholder{
    height: 60px;
}