.menu-thunbnail-new {
    box-shadow: rgb(221 221 221) 0px 0px 10px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #eee;
    overflow: hidden;
    background: white;
    cursor: pointer;
}

.menu-thunbnail-new img{

    object-fit: cover !important;
    width: 100% !important;
    height: 150px;
}

.menu-thunbnail-new-title{
    min-height: 50px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--clr);
    color: white;
}