.product .variant-dropdown{
    font-size: 12px;
    text-align: left;
    font-weight: bold;
    color: black;
    padding: 0px 15px;
}


.product-thumbnail-new{
    box-shadow: rgb(221 221 221) 0px 0px 10px;
    margin-bottom: 35px;
    text-align: center;
    width: 100%;
    height: 320px;
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #eee;
    overflow: hidden;
    background: white;
}

.product-thumbnail-new img{
    object-fit: cover;
    height: 255px;
}

.mobile-product-list-new{
    background-color: white;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    border-radius: 10px;
}

.product-list-new
{
    margin-bottom: 10px;
    display: flex;
}
.product-list-new img {
    border: 1px solid rgba(48, 47, 47, 0.125);
    object-fit: cover;
    width: 100px;
    height: 100px;
    border-radius: 10px;
}